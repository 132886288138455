// Functions first
@import "../node_modules/bootstrap/scss/functions";

// Variable overrides second
$primary: #900;
$enable-shadows: true;
$prefix: "mo-";

// @import "../node_modules/bootstrap/scss/bootstrap";


// Required Bootstrap imports
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// Optional components
@import "../node_modules/bootstrap/scss/containers";
// @import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/helpers";
@import "../node_modules/bootstrap/scss/utilities/api"; // needed for containers, text center, etc

img.logo {
    max-width: 90%;
}

.jumbotron {
    background-color: #eaefe9;
}

.bg-light {
    background-color: #f9faf8 !important;
}

.bg-white {
    background-color: #fff;
}

.jumbotron {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.jumbotron-jpg {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://www.ongoingadventure.com/wp-content/themes/seattle-travelers/img/1600-header.jpg');
}

.jumbotron-webp {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://www.ongoingadventure.com/wp-content/themes/seattle-travelers/img/1600-header.webp');
}

@media only screen and (min-width: 1280px) {
    .jumbotron {
        padding: 4% !important;
    }
}

video {
    width: 100%;
    height: 100%;
}
/*
video {
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}*/

video.p-3:fullscreen {
  padding: 0 !important;
}
